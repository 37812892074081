//
// Forms: Conversational UI
// --------------------------------------------------

// Remove unwanted padding.
.cui {
  padding-top: 0;
  padding-bottom: 0;
}

// Set up flexbox for a column, bottom first chat container.
.chat-container {
  .flex-display(flex);
  .flex-direction(column);
  .flex-justify-content(flex-end);
  min-height: 100vh;
  padding: 15px 5px;
  width: 100%;
}

// General styling chat messages. Default styling is the bot reply.
.chat-message {
  .flex-align-items(center);
  .flex-display(flex);
  margin-bottom: 0.3333333333rem;
  transition: all .5s ease-in-out;
  width: 100%;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-name: fly-in;
  animation-name: fly-in;
}
// Add extra margin-top when the next message is from the other conversing party.
.chat-message + .chat-message--client,
.chat-message--client + .chat-message:not(.chat-message--client) {
  margin-top: 1.1111111111rem;
}
.chat-message--client + .chat-message--client {
  margin-top: 0;
}
// Loading message bubble.
.chat-loading {
  height: 20px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem;
  position: relative;
  text-align: center;
  width: 46px;
  span {
    -webkit-animation: wave 1.3s linear infinite;
    animation: wave 1.3s linear infinite;
    background: #d8d8d8;
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    margin-right: 3px;
    width: 10px;
    &:nth-child(2) {
      animation-delay: -1.1s;
    }
    &:nth-child(3) {
      animation-delay: -0.9s;
    }
  }
}
// Client response.
.chat-message--client {
  .flex-direction(row-reverse);
  .chat-message__inner {
    background-color: #683B8B;
    border-radius: 1.2222222222rem 1.2222222222rem 0 1.2222222222rem;
    p {
      color: @white;
    }
  }
  .form-item {
    margin-bottom: 0;
  }
}

// Client input.
.chat-message--input {
  .chat-message__inner {
    background-color: transparent;
    border-radius: 0;
    padding: 0;
  }
}

// Content of chat message.
.chat-message__inner {
  background-color: @white;
  border-radius: 1.2222222222rem 1.2222222222rem 1.2222222222rem 0;
  display: inline-block;
  max-width: ~"calc(100% - 65px)";
  padding: 0.7777777778rem 1rem 0.8888888889rem;
  .chat-message--video & {
    width: ~"calc(100% - 65px)";
  }
  p,
  .message {
    color: @black;
    margin-bottom: 0;
    .inline-image {
      height: auto;
      max-width: 100%;
      width: auto;
    }
    a:not(.btn) {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

// CHECKBOXES
.cui {
  .form-checkboxes {
    background-color: #2E1C3C;
    border-radius: 0.2777777778rem;
    overflow: hidden;
    .form-item {
      margin-bottom: 0;
    }
    input[type="checkbox"] {
      + label {
        border-bottom: 1px solid @color-primary;
        .fontBold;
        padding: 0.6666666667rem 0.7777777778rem 0.6666666667rem 2.7777777778rem;
        min-width: 250px;
        transition: background-color 0.25s ease;
        &::before {
          display: none;
        }
        &::after {
          background-image: url('../images/check-white.svg') !important;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 26px 22px;
          height: 100%;
          left: 0;
          opacity: 0.075;
          top: 0;
          width: 2.7777777778rem;
        }
      }
      &:checked + label {
        background-color: @color-secondary;
        &::after {
          background-image: url('../images/check-white.svg');
        }
      }
    }
  }
}

// RADIO BUTTONS
.cui {
  .form-radios {
    &.form-radios-2,
    &.form-radios-multiple {
      .form-item {
        margin-bottom: 0.3333333333rem;
      }
      input[type="radio"] {
        + label {
          background-color: @color-secondary;
          border-color: darken(@color-secondary, 7%);
          border-radius: 0.2777777778rem;
          color: @white !important;
          font-size: 1rem;
          .fontBold;
          line-height: 1.3333333333em;
          min-width: 6.6666666667rem;
          padding: 0.8333333333rem 1rem 0.7777777778rem;
          transition: background-color .2s ease-in-out, color .2s ease-in-out;
          &:hover {
            background-color: darken(@color-secondary, 5%);
            border-color: darken(@color-secondary, 14%);
          }
          &::before,
          &::after {
            display: none;
          }
          i {
            display: block;
            font-size: 0.7777777778rem;
            .fontItalic;
            line-height: 1.2857142857em;
            padding-bottom: 0.2222222222rem;
          }
        }
      }
    }
  }
}

// TEXTFIELD AND TEXTAREA
.cui {
  .form-type-textfield {
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="tel"],
    input[type="number"],
    input[type="date"] {
      height: 3rem;
    }
    input,
    textarea {
      background-color: rgba(0, 0, 0, 0.3);
      box-shadow: inset 3px 3px 6px 0 rgba(0,0,0,0.50);
      color: @white;
      .fontBold;
      text-align: center;
      transition: all .2s ease-in-out;
      &:focus {
        background-color: rgba(0, 0, 0, 0.4);
      }
      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: rgba(255, 255, 255, 0.2);
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: rgba(255, 255, 255, 0.2);
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: rgba(255, 255, 255, 0.2);
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: rgba(255, 255, 255, 0.2);
      }
    }
    input[type="text"],
    input[type="date"] {
      .fontRegular;
      text-align: left;
    }
    textarea {
      .fontRegular;
      text-align: left;
      width: 100%;
    }
  }
  .chat-message--textarea {
    .chat-message__inner {
      width: 100%;
    }
  }
}

// RANGE SLIDER
.cui {
  .chat-message--question-slider {
    .chat-message__inner {
      width: 100%;
    }
  }
  .form-type-range {
    margin: 1rem 0;
    width: 100%;
  }
  .rangeslider {
    background-color: rgba(0, 0, 0, 0.3);
    box-shadow: inset 3px 3px 6px 0 rgba(0,0,0,0.50);
    .rangeslider__handle {
      background-color: @white;
    }
  }
}

// CHAT NAVIGATION LINKS
.cui {
  .chat-message__navigation {
    margin-top: 10px;
    text-align: right;
    color: @white;
    opacity: 0.5;
    a {
      color: @white;
    }
  }
}

@media (min-width: @bp-small) {
  .chat-container {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
