//
// Animation
// --------------------------------------------------

@keyframes noot-top {
  0% {
    transform: translate(0, 0) rotate(0);
  }
  9% {
    transform: translate(1px, -2px) rotate(0.5deg);
  }
  20% {
    transform: translate(0, 2px) rotate(-1deg);
  }
  49% {
    transform: translate(-1px, 4px) rotate(-3deg);
  }
  60% {
    transform: translate(0, 2px) rotate(-2deg);
  }
  100% {
    transform: translate(-2px, 10px) rotate(-7.25deg);
  }
}

@keyframes noot-bottom {
  0% {
    transform: translate(0, 0) rotate(0);
  }
  9% {
    transform: translate(-1px, -2px) rotate(-0.4deg);
  }
  20% {
    transform: translate(0, 0) rotate(0);
  }
  49% {
    transform: translate(-1px, 0) rotate(3deg);
  }
  60% {
    transform: translate(0, -3px) rotate(2deg);
  }
  100% {
    transform: translate(3px, 13px) rotate(1.11deg);
  }
}


@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes float-shadow {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.9;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg) translate(-50%, -50%);
    -o-transform: rotate(0deg) translate(-50%, -50%);
    -webkit-transform: rotate(0deg) translate(-50%, -50%);
    transform: rotate(0deg) translate(-50%, -50%);
  }

  100% {
    -moz-transform: rotate(359deg) translate(-50%, -50%);
    -o-transform: rotate(359deg) translate(-50%, -50%);
    -webkit-transform: rotate(359deg) translate(-50%, -50%);
    transform: rotate(359deg) translate(-50%, -50%);
  }
}
@-webkit-keyframes spin {
  0% {
    -moz-transform: rotate(0deg) translate(-50%, -50%);
    -o-transform: rotate(0deg) translate(-50%, -50%);
    -webkit-transform: rotate(0deg) translate(-50%, -50%);
    transform: rotate(0deg) translate(-50%, -50%);
  }

  100% {
    -moz-transform: rotate(359deg) translate(-50%, -50%);
    -o-transform: rotate(359deg) translate(-50%, -50%);
    -webkit-transform: rotate(359deg) translate(-50%, -50%);
    transform: rotate(359deg) translate(-50%, -50%);
  }
}
@-o-keyframes spin {
  0% {
    -moz-transform: rotate(0deg) translate(-50%, -50%);
    -o-transform: rotate(0deg) translate(-50%, -50%);
    -webkit-transform: rotate(0deg) translate(-50%, -50%);
    transform: rotate(0deg) translate(-50%, -50%);
  }

  100% {
    -moz-transform: rotate(359deg) translate(-50%, -50%);
    -o-transform: rotate(359deg) translate(-50%, -50%);
    -webkit-transform: rotate(359deg) translate(-50%, -50%);
    transform: rotate(359deg) translate(-50%, -50%);
  }
}
@-ms-keyframes spin {
  0% {
    -moz-transform: rotate(0deg) translate(-50%, -50%);
    -o-transform: rotate(0deg) translate(-50%, -50%);
    -webkit-transform: rotate(0deg) translate(-50%, -50%);
    transform: rotate(0deg) translate(-50%, -50%);
  }

  100% {
    -moz-transform: rotate(359deg) translate(-50%, -50%);
    -o-transform: rotate(359deg) translate(-50%, -50%);
    -webkit-transform: rotate(359deg) translate(-50%, -50%);
    transform: rotate(359deg) translate(-50%, -50%);
  }
}
@keyframes spin {
  0% {
    -moz-transform: rotate(0deg) translate(-50%, -50%);
    -o-transform: rotate(0deg) translate(-50%, -50%);
    -webkit-transform: rotate(0deg) translate(-50%, -50%);
    transform: rotate(0deg) translate(-50%, -50%);
  }

  100% {
    -moz-transform: rotate(359deg) translate(-50%, -50%);
    -o-transform: rotate(359deg) translate(-50%, -50%);
    -webkit-transform: rotate(359deg) translate(-50%, -50%);
    transform: rotate(359deg) translate(-50%, -50%);
  }
}

// CUI - LOADING BUTTON
@-webkit-keyframes wave {
  0%, 60%, 100% {
    -webkit-transform: initial;
    transform: initial;
  }

  30% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
@keyframes wave {
  0%, 60%, 100% {
    -webkit-transform: initial;
    transform: initial;
  }

  30% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

// CUI - FLY-IN MESSAGES
@keyframes fly-in {
  0% {
    -webkit-transform: scale(0.85) translateY(10%);
    -ms-transform: scale(0.85) translateY(10%);
    transform: scale(0.85) translateY(10%);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1) translateY(0);
    -ms-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    opacity: 1;
  }
}