//
// Variables
// --------------------------------------------------

// COLOURS
@black:                   #000;
@gray-darker:             lighten(@black, 13.5%); // #222
@gray-dark:               lighten(@black, 20%);   // #333
@gray:                    lighten(@black, 33.5%); // #555
@gray-light:              lighten(@black, 46.7%); // #777
@gray-lighter:            lighten(@black, 93.5%); // #eee
@white:                   #ffffff;

@color-primary:           #432957; // Very dark desaturated violet
@color-secondary:         #ff8b0e; // Vivid orange
@color-tertiary:          @gray-lighter;
@color-quaternary:        @gray-darker;
@color-quinary:           #20142a; // Very dark (mostly black) violet

@color-success:           #72c24a; // Moderate green
@color-warning:           #fcdc23; // Vivid yellow
@color-error:             #d9534f; // Soft red
@color-focus:             #faf5cb; // Light grayish yellow

@color-facebook:          #3b5998;
@color-flickr-blue:       #0063dc;
@color-flickr-pink:       #ff0084;
@color-instagram:         #3f729b;
@color-linkedin:          #0068b5;
@color-twitter:           #00a6eb;
@color-youtube:           #b90606;
@color-rss:               #f26522;

// SCAFFOLDING
@body-bg:                 @color-quaternary;
@text-color:              @color-primary;
@link-color:              @color-primary;
@link-color-hover:        darken(@link-color, 15%);
@border-color:            @gray-lighter;

// TYPOGRAPHY
@font-family-sans-serif:  'Helvetica Neue', Helvetica, Arial, sans-serif;
@font-family-serif:       Georgia, 'Times New Roman', Times, serif;

@font-family-cursive:     'pacificoregular', cursive;

@base-font-size:          18px; // Was 17
@base-line-height:        1.7647058824em;

// MEDIA QUERIES
@bp-x-small:              480px;
@bp-small:                768px;
@bp-medium:               992px;
@bp-large:                1200px;
@bp-x-large:              1440px;