//
// Date input polyfill
// --------------------------------------------------


date-input-polyfill[data-open="true"] {
  background-color: @color-primary;
  color: @white;
  .fontRegular;
  padding: 15px;
  z-index: 100;
  .translate(-30px, 0);
  select, table, td, th {
    background-color: @color-primary;
    color: @white;
  }
  .yearSelect-wrapper,
  .monthSelect-wrapper {
    position: relative;
    &::after {
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid @white;
      content: '';
      display: block;
      height: 0;
      position: absolute;
      right: 7px;
      top: 50%;
      width: 0;
      .translate(0, -50%);
    }
    select {
      border: none;
    }
  }
  .yeartSelect-wrapper {
    margin-right: 5%;
    width: 20%;
  }
  .monthSelect-wrapper {
    margin-right: 5%;
    width: 45%;
  }
  select, button {
    height: 26px;
  }
  button {
    .btn;
    .btn-secondary;
    font-size: 0.8rem;
    padding: 0;
    width: 25%;
    .border-radius(3px);
  }
  table {
    margin-top: 15px;
    th {
      .fontBold;
      .opacity(0.75);
      width: 14.2857142857%;
    }
    td[data-day] {
      .fontSemibold;
      height: 36px;
      &:hover {
        background-color: @color-secondary;
      }
    }
  }
}