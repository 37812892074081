//
// Score
// --------------------------------------------------

// SCORE VERKORTE LIBRA
.mainContent.libra-score {
  padding-top: 15px;
  .page-title {
    color: @white;
    margin-bottom: 0.7857142857em;
  }
}
.score-cloud {
  background-color: @white;
  margin-bottom: 45px;
  padding: 15px 15px 30px;
  position: relative;
  text-align: center;
  .border-radius(30px);
  .deco {
    left: 50%;
    position: absolute;
    bottom: -25px;
    .translate(-50%, 0);
  }
  .score-cloud-scores {
    height: 220px;
    margin: 0 auto 15px;
    position: relative;
    width: 260px;
  }
  .theme {
    color: #b7b7b7;
    position: absolute;
    &.is-hold-on {
      color: @color-success;
    }
    &.is-room-improvement {
      color: @color-secondary;
      .improvement-indicator {
        background-color: @color-secondary;
        border: 2px solid @white;
        color: @white;
        font-size: 1rem;
        .fontSemibold;
        height: 25px;
        left: -12px;
        line-height: 1em;
        padding: 2px 0;
        position: absolute;
        top: 55%;
        width: 25px;
        .border-radius(25px);
        .translate(0, -50%);
      }
    }
    i {
      font-size: 3rem;
      &.icon-beweging,
      &.icon-cholestrol {
        font-size: 3.2rem;
      }
      &.icon-alcohol {
        font-size: 2.7rem;
      }
      &.icon-roken,
      &.icon-dieet,
      &.icon-diabetes {
        font-size: 2.5rem;
      }
      &.icon-overgewicht {
        font-size: 2.3rem;
      }
    }
    &.theme-1 { left: 40px; top: 15px; }
    &.theme-2 { left: 105px; top: 14px; }
    &.theme-3 { left: 169px; top: 13px; }
    &.theme-4 { left: 7px; top: 68px; }
    &.theme-5 { left: 75px; top: 83px; }
    &.theme-6 { left: 139px; top: 75px; }
    &.theme-7 { left: 210px; top: 68px; }
    &.theme-8 { left: 17px; top: 132px; }
    &.theme-9 { left: 64px; top: 164px; }
    &.theme-10 { left: 115px; top: 126px; }
    &.theme-11 { left: 155px; top: 165px; }
    &.theme-12 { left: 205px; top: 129px; }
  }
  p {
    color: @black;
    line-height: 1.4705882353em;
    margin-bottom: 0;
    strong {
      color: @color-secondary;
    }
  }
}

.score-info {
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  text-align: center;
  .logo {
    margin-bottom: 20px;
  }
  p {
    color: @white;
    line-height: 1.4705882353em;
  }
  .btn {
    display: block;
    margin-bottom: 15px;
  }
  .qr-code {
    margin-bottom: 15px;
    img {
      background-color: @white;
    }
  }
  .redo {
    color: @color-secondary;
    .fontItalic;
    text-decoration: underline;
    text-transform: lowercase;
    &:hover {
      .opacity(1);
    }
  }
}

@media (min-width: @bp-x-small) {
  .score-cloud {
    .score-cloud-scores {
      height: 270px;
      .scale(1.2);
      -webkit-transform-origin: top center;
      -moz-transform-origin: top center;
      -ms-transform-origin: top center;
      -o-transform-origin: top center;
      transform-origin: top center;
    }
  }
}

@media (min-width: 600px) {
  .score-cloud {
    .score-cloud-scores {
      height: 300px;
      .scale(1.3);
    }
  }
}

@media (min-width: @bp-small) {
  .score-cloud {
    .score-cloud-scores {
      height: 330px;
      .scale(1.4);
    }
  }
}

@media (min-width: @bp-medium) {
  .score-cloud {
    .score-cloud-scores {
      height: 350px;
      .scale(1.5);
    }
    p {
      font-size: 1.1764705882rem;
    }
  }
}